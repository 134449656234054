import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Stack } from "@mui/system";

import store from "../../mobx";
import { SubsectionStack } from "./SubsectionStack";

export const Testimonials = observer(function Testimonials({
  title = "Testimonials",
  spacing = 8,
}) {
  const { isPhone } = store.mediaBreakpointStore;

  const items = [
    {
      text: "This app has been a game-changer for us. Before using it, we had a couple of security issues that were difficult to manage. Now, the QR code scanning allows us to verify the identity of patrons swiftly, providing a safer environment for everyone.",
      name: "Sarah, Bar Owner",
    },
    {
      text: "I always felt a little uneasy going out late at night. However, when I saw that the bar I frequent had started using this QR code safety app, it put my mind at ease. The fact that everyone is vetted before entering makes me feel much safer.",
      name: "Mike, Patron",
    },
    {
      text: "Implementing the QR code scanning app into our entry process was quick and painless, but the benefits have been immense. Incidents have gone down, and patrons have even commented about how they appreciate our commitment to their safety.",
      name: "Javier, Bar Owner",
    },
    {
      text: "As a single woman, going out to bars has always come with its set of worries for me. This app has been a huge relief, and I'm more comfortable knowing there's an extra layer of security.",
      name: "Emily, Patron",
    },
    {
      text: "I've been in the bar business for decades, and I can say without a doubt, this QR code app is one of the best safety measures I've seen. It’s simple, unobtrusive, yet highly effective. My staff and I can focus more on providing great service rather than worrying about security risks.",
      name: "Robert, Bar Owner",
    },
  ];

  return (
    <SubsectionStack title={title} spacing={spacing}>
      <Stack
        width="100%"
        alignItems="center"
        justifyContent="center"
        spacing={isPhone ? 4 : 6}
      >
        {items.map(({ text, name }, index) => (
          <Typography
            textAlign="center"
            key={index}
            sx={{
              fontSize: 18,
              color: "black",
              fontStyle: "italic",
            }}
          >
            "{text} "
            <br />
            <Typography
              component="span"
              sx={{
                fontSize: 18,
                textTransform: "capitalize",
                fontStyle: "normal",
              }}
            >
              - <b>{name}</b>
            </Typography>
          </Typography>
        ))}
      </Stack>
    </SubsectionStack>
  );
});
