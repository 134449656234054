import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";
import store from "../../mobx";
import { fetchFromApiServer } from "../../services/api";

const { dialog } = store;

// const createIntentUrl =
//   process.env.NODE_ENV === "production"
//     ? "https://createintent-5gnvjtys6a-uc.a.run.app"
//     : "http://127.0.0.1:5001/watchdog-6de84/us-central1/createIntent";

// const verifyIntentUrl =
//   process.env.NODE_ENV === "production"
//     ? "https://verifyintent-5gnvjtys6a-uc.a.run.app"
//     : "http://127.0.0.1:5001/watchdog-6de84/us-central1/verifyIntent";

// const createSubscriptionUrl =
//   "https://createsubscription-5gnvjtys6a-uc.a.run.app";

// const verifyIntentUrl = "https://verifyintent-5gnvjtys6a-uc.a.run.app";

// const unsubscribeUrl =
//   "https://unsubscribeFromSubscription-5gnvjtys6a-uc.a.run.app";

const unsubscribeUrl =
  process.env.NODE_ENV === "production"
    ? "https://unsubscribefromsubscription-5gnvjtys6a-uc.a.run.app"
    : "http://127.0.0.1:5001/watchdog-6de84/us-central1/unsubscribeFromSubscription";

export const setUnsubscribeDialog = () => {
  dialog.set.dialog({
    fullScreen: store.mediaBreakpointStore.isPhone,
    Title: () => <DialogTitle>Unsubscribe</DialogTitle>,
    Content: () => (
      <DialogContent>
        <Typography paragraph>Are you sure you want to unsubscribe?</Typography>
        <Typography>
          <b>Last.Year Pro</b> will stop working on{" "}
          <b>{store.user.subscriptionEndString}</b>
        </Typography>
      </DialogContent>
    ),
    Actions: () => {
      const [loading, setLoading] = useState(false);

      return (
        <DialogActions>
          <Button
            color="inherit"
            variant="text"
            onClick={() => dialog.set.open(false)}
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={async () => {
              setLoading(true);

              try {
                var response = await fetchFromApiServer(unsubscribeUrl, {
                  method: "post",
                });
              } catch (error) {
                console.error(error);
              } finally {
                setLoading(false);
                dialog.set.open(false);

                store.snackbar.set.message(
                  response?.ok
                    ? `Unsubscribed from Last.Year Pro`
                    : `Something went wrong, please contact support at billing@qrwatchdog.com`
                );
              }
            }}
            startIcon={
              loading && <CircularProgress color="inherit" size={16} />
            }
          >
            Unsubscribe
          </Button>
        </DialogActions>
      );
    },
  });
  dialog.set.open(true);
};
