import { observer } from "mobx-react-lite";
import { Stack } from "@mui/material";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";

// the purpose of this component is to make a separate three.js instance
// and dedicate it to its own working
function _ThreeRenderer({ children, stackProps, titleComponent, ...props }) {
  return (
    <Stack alignItems={"center"} justifyContent={"center"} {...stackProps}>
      {titleComponent}
      <Canvas
        camera={{ position: [0, 0, 40], near: 0.1, far: 10e3 }}
        // orthographic={true}
        //
        gl={{ antialias: true }}
        dpr={[1, 2]} // Device pixel ratio for high-resolution screens
        flat={true}
        {...props}
      >
        {children}
        <OrbitControls />
      </Canvas>
    </Stack>
  );
}

const ThreeRenderer = observer(_ThreeRenderer);

export { ThreeRenderer };
