import { makeAutoObservable } from "mobx";
import GlobalStore from "../globalStore";

class ClipperStore {
  options = {
    autoGain: true,
  };

  constructor(_ = new GlobalStore()) {
    this._ = _;
    makeAutoObservable(this);
  }

  set = {
    options: {
      autoGain: (autoGain = true) => {
        this.options.autoGain = autoGain;
      },
    },
  };
}

export default ClipperStore;
