import { makeAutoObservable, transaction } from "mobx";
import GlobalStore from "../../globalStore.js";

import WebRenderer from "@elemaudio/web-renderer";
import { el } from "@elemaudio/core";

import { graphFromPipeline } from "./utilties/index.js";

import { gainToDb } from "./utilties/gainToDb.js";
import {
  constructFrequencyBins,
  constructMagnitudeObjects,
} from "./utilties/constructFrequencyBins.js";

const TEST_HZ = 120;

const audioContext = new AudioContext();

const CORE = new WebRenderer();

class AudioProcessingStore {
  isLoading = false;
  max_dB = 0;
  min_dB = -70;

  playing = {
    signal: false,
    sample: false,
  };

  constructor(_ = new GlobalStore()) {
    this._ = _;

    for (const setter of Object.values(this.set)) {
      if (setter.constructor.name === "Object") {
        const subSetters = Object.values(setter);

        for (const subSetter of subSetters) {
          subSetter();
        }

        continue;
      }
      setter();
    }

    makeAutoObservable(this);
  }

  set = {
    playing: {
      signal: (playing = false) => {
        this.playing.signal = playing;
      },
      sample: (playing = false) => {
        this.playing.sample = playing;
      },
    },
    core: (core) => {
      this.core = core;
    },
    audioWorkletNode: (audioWorkletNode) => {
      this.audioWorkletNode = audioWorkletNode;
    },
    render: (render = () => undefined) => {
      this.render = () => {
        if (this.playingSignal) {
          render();
        }
      };
    },
    sampleRate: (sampleRate = 0) => {
      this.sampleRate = sampleRate;
    },
    scopeBins: (scopeBins = []) => {
      this.scopeBins = scopeBins;
    },
    dbLevelPre: (dbLevelPre = 0) => {
      this.dbLevelPre = dbLevelPre;
    },
    dbLevelPostClipping: (dbLevelPostClipping) => {
      this.dbLevelPostClipping = dbLevelPostClipping;
    },
    dbLevelMaster: (dbLevelMaster = 0) => {
      this.dbLevelMaster = dbLevelMaster;
    },
    magnitudeObjects: (magnitudeObjects = []) => {
      this.magnitudeObjects = magnitudeObjects;
    },
    clipAmountGain: (clipAmountGain = 1) => {
      this.clipAmountGain = Math.max(clipAmountGain, 0);
    },
    postClipAmountGain: (postClipAmountGain = 1) => {
      this.postClipAmountGain = postClipAmountGain;
    },
    numberOfLevelReadings: (numberOfLevelReadings = 200) => {
      this.numberOfLevelReadings = numberOfLevelReadings;
    },
    levelReadings: (
      levelReadings = new Array(this.numberOfLevelReadings).fill(0)
    ) => {
      this.levelReadings = levelReadings;
    },
    lastLevelReading: (lastLevelReading = 0) => {
      const newLevelReadings = this.levelReadings.slice(1);

      newLevelReadings.push(lastLevelReading);

      //

      this.set.levelReadings(newLevelReadings);
    },

    numberOfEqReadings: (numberOfEqReadings = 1024) => {
      this.numberOfEqReadings = numberOfEqReadings;
    },
    eqReadings: (
      eqReadings = new Array(this.numberOfEqReadings).fill(null).map(() => [])
    ) => {
      this.eqReadings = eqReadings;
    },
    lastEqReading: (magnitudeObjects = []) => {
      const column = [];
      for (let i = 0; i < magnitudeObjects.length; i++) {
        const magnitudeObject = magnitudeObjects[i];

        const { avgValueDb } = magnitudeObject;

        const intensity = Math.log10(Math.max(avgValueDb, 0.01));

        const freqChunk = intensity;

        column.push(freqChunk);
      }

      const newEqReadings = this.eqReadings.slice(1);

      newEqReadings.push(column);

      this.set.eqReadings(newEqReadings);
    },
  };

  getNormalized_dB = (dB) => {
    const norm = dB / this.min_dB;

    return norm;
  };

  startSignal = () => {
    this.stop();
    this.set.playing.signal(true);
    this._processAudioFile();
  };

  startSample = (file) => {
    this.stop();
    this.set.playing.sample(true);
    this.processAudioFile(file);
  };

  // everything must stop if stop is pressed, it's special
  stop = () => {
    this.core
      ?.render(
        el.const({
          value: 0,
        })
      )
      .catch(console.error);

    transaction(() => {
      this.set.playing.signal(false);
      this.set.playing.sample(false);
    });
  };

  processAudioFile = async (file) => {
    const audioBuffer = await new Promise((resolve, reject) => {
      if (!file) {
        resolve();
      }

      const reader = new FileReader();

      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;

        try {
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
          resolve(audioBuffer);
        } catch (error) {
          reject(error);
        }
      };

      reader.readAsArrayBuffer(file);
    });

    await this._processAudioFile(file, audioBuffer).catch(console.error);
  };

  _processAudioFile = async (file, audioBuffer) => {
    const CTX = new AudioContext();
    this.audioWorkletNode?.disconnect();

    const sampleRate = audioBuffer?.sampleRate || 44.3e3;

    this.set.sampleRate(sampleRate);

    // Initialize the renderer
    const audioWorkletNode = await CORE.initialize(CTX, {
      numInputChannels: 0,
      numOutputChannels: 1,
      processorOptions: {
        sampleRate,
        virtualFileSystem: {
          [file?.name]: audioBuffer?.getChannelData(0),
        },
      },
    });

    this.set.core(CORE);

    audioWorkletNode.connect(CTX.destination);

    this.set.audioWorkletNode(audioWorkletNode);

    const handleFFT = this.handleFFTFactory(sampleRate);

    CORE.on("fft", handleFFT);

    CORE.on("meter", this.handleMeter);

    // this is an oscilloscope
    CORE.on("scope", this.handleScope);

    const render = async () => {
      // 1 second = 87 blocks

      const startAt = 1;

      const secondsToPlay = 120;

      const pipeline = {
        left: [
          ...(file
            ? samplePlayingPipeline({
                file,
                startAt,
                secondsToPlay,
              })
            : pulsatingTestingPipeline(TEST_HZ)),

          (prevEl) =>
            el.meter({ name: "pre-loudness-dB-meter", size: 4096 }, prevEl),
          ///////////////// ALL POST PROCESSING EFFECTS START HERE, METERS FOR POST SIGNALS /////////////////
          // HARD clipper
          (prevEl) => el.min(prevEl, el.const({ value: this.clipAmountGain })),
          (prevEl) => el.max(prevEl, el.const({ value: -this.clipAmountGain })),
          (prevEl) =>
            el.meter({ name: "post-clipping-dB-meter", size: 4096 }, prevEl),

          // GAIN adder after clipping
          (prevEl) =>
            el.mul(prevEl, el.const({ value: this.postClipAmountGain })),
          // Slot for next effect

          ////////// STUFF TO KEEP THINGS CONSISTENT BETWEEN DAWS //////////
          // keep the signal between [-1, 1]
          // (prevEl) => el.min(prevEl, el.const({ value: 2 })),
          // (prevEl) => el.max(prevEl, el.const({ value: -2 })),
          //////////////// END OF POST PROCESSING CHAIN ////////////////

          // (prevEl) => el.gain2db(prevEl),
          // (prevEl) => el.db2gain(prevEl),
          (prevEl) => el.meter({ name: "master-dB-meter" }, prevEl),
          (prevEl) => el.fft({ name: "fft-meter", size: 2048 }, prevEl),
          (prevEl) => el.scope({ name: "left", size: 256 }, prevEl),
        ],
        right: [],
      };

      const graph = graphFromPipeline(pipeline);

      await CORE.render(graph);
    };

    this.set.render(render);

    this.render();

    const a = 2;
  };

  handleFFTFactory = (sampleRate) => {
    const minFrequency = 1;
    const maxFrequency = sampleRate / 2;
    const totalNumberOfBins = 60;
    const floorFreq = 39;

    const frequencyBins = constructFrequencyBins({
      minFrequency,
      maxFrequency,
      totalNumberOfBins,
      floorFreq,
    });

    return ({ source, data: { real, imag } }) => {
      const realArray = Array.from(real);
      const imagArray = Array.from(imag);

      const magnitudeObjects = constructMagnitudeObjects(
        frequencyBins,
        realArray,
        imagArray,
        sampleRate
      );

      transaction(() => {
        this.set.magnitudeObjects(magnitudeObjects);
        // this.set.lastEqReading(magnitudeObjects);
      });
    };
  };

  handleScope = ({ data, source }) => {
    const bins = data[0];

    this.set.scopeBins(Array.from(bins));
  };

  handleMeter = (event) => {
    const { max, min, source } = event;

    // NOTE THIS IS NOT ACTUALLY DB
    const gain = Math.max(max, Math.abs(min));

    const dB = gainToDb(gain);

    // we'll say 0 gain is equal to -70dB

    const ratio = -(Math.abs(dB) / this.min_dB);

    const adjustedDb = 1 - ratio;

    if (source === "pre-loudness-dB-meter") {
      this.set.dbLevelPre(adjustedDb);
    }

    if (source === "post-clipping-dB-meter") {
      this.set.dbLevelPostClipping(adjustedDb);
    }
    // 1 seconds = 87 blocks
    if (source === "master-dB-meter") {
      transaction(() => {
        this.set.dbLevelMaster(adjustedDb);
        this.set.lastLevelReading(adjustedDb);
      });
    }
  };

  get playingSignal() {
    return this.playing.sample || this.playing.signal;
  }

  get clipAmountdB() {
    const dB = gainToDb(this.clipAmountGain);

    return dB;
  }

  get normalizedClipAmountdB() {
    const dbRemoved = this.clipAmountdB / this.min_dB;

    return dbRemoved;
  }

  get complimentNormalizedClipAmountdB() {
    const complement = 1 - this.normalizedClipAmountdB;
    return complement;
  }
}

function samplePlayingPipeline({ file, startAt, secondsToPlay }) {
  return [
    () => el.train(1 / secondsToPlay),

    (prevEl) =>
      //
      el.sample(
        {
          path: file.name,
        },
        prevEl,
        // Offset in samples from the start of the sample where playback starts
        startAt,
        startAt + secondsToPlay
      ),
  ];
}

function pulsatingTestingPipeline(hz) {
  return [
    // make initial node, train()
    // Outputs a pulse train alternating between 0 and 1 at the given rate
    // () => el.cycle(440),
    // () => el.blepsaw(1e3),
    // basic lfo for modulating gain at a given frequency
    () => el.cycle(hz),
    (prevEl) =>
      el.mul(
        prevEl,
        // prevent saturation the signal
        el.abs(el.cycle(0.25))
      ),
  ];
}

export default AudioProcessingStore;
