import { observer } from "mobx-react-lite";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Grow,
  ListItemButton,
} from "@mui/material";
import {
  ArticleOutlined as ReportsIcon,
  Home as HomeIcon,
  People,
  QrCode,
  Settings,
  Logout,
  ChevronLeft,
  Money,
} from "@mui/icons-material";
import store from "../../mobx";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import BrandText from "../BrandText";
import { alpha, Box, Stack } from "@mui/system";
import { useLocation } from "react-router";
import { setSubscribeDialog } from "../Dialog/setSubscribeDialog";

// import { Firebase } from '../../services/firebase';

export default observer((props) => {
  const {
    userStore,
    drawerStore,

    mediaBreakpointStore: { isPhone },
  } = store;
  const navigate = useNavigate();

  const location = useLocation();

  const [items, setItems] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);
  const [bottomItems, setBottomItems] = useState([]);

  useEffect(() => {
    const defaultItem = {
      name: "Home",
      path: "/",
      Icon: HomeIcon,
    };

    const items = [
      {
        name: "QR Code",
        path: "/qr",
        Icon: QrCode,
      },
      {
        name: "Reports",
        path: "/reportDashboard",
        Icon: ReportsIcon,
      },
    ];

    const additionalItems = [
      // {
      //   name: "Team",
      //   path: "/team",
      //   Icon: People,
      // },
      // {
      //   name: "Account",
      //   path: "/account",
      //   Icon: Settings,
      // },
    ];

    const bottomItems = [
      ...(!userStore.subscribed
        ? [
            // {
            //   name: "Subscribe",
            //   onClick: setSubscribeDialog,
            //   props: {
            //     sx: (theme) => ({
            //       // border: "1px solid red",
            //       // borderRadius: 4,
            //       boxShadow: `0px 0px 2px 0.25px ${theme.palette.secondary.main}`,
            //       color: "secondary.light",
            //       "& svg": { color: "secondary.light" },
            //     }),
            //   },
            //   Icon: Money,
            // },
          ]
        : []),
      // {
      //   name: "Logout",

      //   onClick: async () => {
      //     await userStore.signOut();

      //     if (isPhone) {
      //       drawerStore.toggle();
      //     }

      //     navigate("/");
      //   },
      //   Icon: Logout,
      // },
    ];

    setItems([defaultItem, ...items]);
    setAdditionalItems(additionalItems);
    setBottomItems(bottomItems);

    // if (team.name) {
    //   setItems([defaultItem, ...items]);
    //   setAdditionalItems(additionalItems);
    //   setBottomItems(bottomItems);

    //   return;
    // }
    setItems([defaultItem]);
  }, [userStore, userStore.subscribed, drawerStore, isPhone, navigate]);

  // useEffect(() => {
  //   setSubscribeDialog();
  // }, [setSubscribeDialog]);

  const drawerOpenOrPhone = isPhone || drawerStore.open;

  return (
    <Drawer
      ModalProps={{
        // internal bug with mui causes the portal component to remain mounted even if keepMounted is set to false
        // suggests the cleanup sequence has an edge case that fails to clean it up... Probably because we are switching variants
        disablePortal: true,
      }}
      variant={isPhone ? "temporary" : "permanent"}
      anchor={"left"}
      open={drawerStore.open}
      onClose={drawerStore.toggle}
      PaperProps={{
        sx: {
          width: drawerOpenOrPhone ? 300 : 56,
          transition: isPhone ? undefined : "all 300ms",
          overflowX: "hidden",
          "& .MuiListItem-root": {
            height: 48,
          },
        },
      }}
    >
      <>
        <Stack
          direction="row"
          pl={drawerStore.open ? 2 : undefined}
          py={1}
          width={drawerStore.open || isPhone ? undefined : 56}
          alignItems="center"
          justifyContent={
            drawerStore.open || isPhone ? "space-between" : "center"
          }
        >
          {(drawerStore.open || isPhone) && (
            <Grow in={true}>
              <Box onClick={() => navigate("/")}>
                <BrandText
                  textAlign="left"
                  pb={0.25}
                  // disableFullText={isPhone}
                />
              </Box>
            </Grow>
          )}

          <IconButton onClick={drawerStore.toggle}>
            <ChevronLeft
              sx={{
                transition: "all 300ms",
                ...(!drawerStore.open
                  ? { transform: "scaleX(-1)" }
                  : undefined),
              }}
            />
          </IconButton>
        </Stack>
        <Divider />
      </>

      <List sx={{ py: 0 }}>
        {items.map(({ name, path, Icon }) => (
          <ListItemButton
            key={name}
            onClick={() => {
              navigate(path);
              if (isPhone) {
                drawerStore.toggle();
              }
            }}
            sx={(theme) => ({
              bgcolor:
                location.pathname === path
                  ? alpha(theme.palette.primary.main, 0.1)
                  : undefined,
            })}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            {(drawerStore.open || isPhone) && <ListItemText primary={name} />}
          </ListItemButton>
        ))}
      </List>

      <Divider />
      <List sx={{ pt: 0 }}>
        {additionalItems.map(({ name, path, Icon }) => {
          return (
            <ListItemButton
              key={name}
              onClick={() => {
                navigate(path);
                if (isPhone) {
                  drawerStore.toggle();
                }
              }}
              sx={(theme) => ({
                bgcolor:
                  location.pathname === path
                    ? alpha(theme.palette.primary.main, 0.1)
                    : undefined,
              })}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              {(drawerStore.open || isPhone) && <ListItemText primary={name} />}
            </ListItemButton>
          );
        })}
      </List>
      <Stack height={"100%"} direction="column" justifyContent="flex-end">
        <List sx={{ pb: 0 }}>
          {bottomItems.map(({ Icon, name, onClick, props }, index) => {
            return (
              <ListItemButton key={index} {...props} onClick={onClick}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                {(drawerStore.open || isPhone) && (
                  <ListItemText primary={name} />
                )}
              </ListItemButton>
            );
          })}
        </List>
      </Stack>
    </Drawer>
  );
});
