import { observer } from "mobx-react-lite";
import { Paper, Typography } from "@mui/material";
import { ThreeRenderer } from "./ThreeRenderer";
import store from "../../../../mobx";
import * as THREE from "three";
import { extend } from "@react-three/fiber";

const BAR_SCALING_FACTOR = 20;
const LEFT_ORIGIN = -49;
const BOTTOM_ORIGIN = 0;
const barWidth = 0.5;

// Extend ShapeGeometry into R3F
extend({ ShapeGeometry: THREE.ShapeGeometry });

function _MacroWaveForm() {
  const { audioProcessingStore } = store;

  const waveformPoints = audioProcessingStore.levelReadings.map((level, i) => [
    i * barWidth + LEFT_ORIGIN, // X-coordinate
    level * BAR_SCALING_FACTOR + BOTTOM_ORIGIN, // Y-coordinate
  ]);

  const mirroredPoints = waveformPoints.map(([x, y]) => [
    x,
    -y + 2 * BOTTOM_ORIGIN, // Mirror on X-axis
  ]);

  const combinedPoints = [...waveformPoints, ...mirroredPoints.reverse()]; // Combine the original and mirrored points

  const shape = new THREE.Shape();
  if (combinedPoints.length > 0) {
    const [startX, startY] = combinedPoints[0];
    shape.moveTo(startX, startY);

    combinedPoints.forEach(([x, y]) => {
      shape.lineTo(x, y);
    });
    shape.closePath();
  }

  return (
    <Paper sx={{ bgcolor: "black" }}>
      <ThreeRenderer
        titleComponent={<Typography color="white">Macro Waveform</Typography>}
        stackProps={{
          spacing: 0,
          width: 246,
        }}
      >
        {/* Mesh rendering the filled waveform */}
        <mesh>
          <shapeGeometry args={[shape]} />
          <meshBasicMaterial color="cyan" transparent opacity={0.2} />
        </mesh>
      </ThreeRenderer>
    </Paper>
  );
}

const MacroWaveForm = observer(_MacroWaveForm);

export { MacroWaveForm };
