import { observer } from "mobx-react-lite";
import { Snackbar } from "@mui/material";

import store from "../../mobx";

export default observer(function SnackbarComponent() {
  const { snackbarStore } = store;

  return (
    <Snackbar
      message={snackbarStore.message}
      open={snackbarStore.open}
      onClose={() => {
        snackbarStore.toggle();
      }}
      //
      autoHideDuration={5e3}
      TransitionProps={{
        onExited: () => {
          snackbarStore.set.message("");
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      ContentProps={{
        sx: {
          borderRadius: 20,
          color: "white",
          bgcolor: "grey.900",
          textAlign: "center",
        },
      }}
      sx={{
        "& .MuiSnackbarContent-message": {
          width: "100%",
        },
      }}
    />
  );
});
