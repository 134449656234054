import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Paper, Typography } from "@mui/material";
import { ThreeRenderer } from "./ThreeRenderer";
import store from "../../../../mobx";

const BAR_SCALING_FACTOR = 10;
const LEFT_ORIGIN = -60;
const BOTTOM_ORIGIN = -30;

const yScale = 3;
const xScale = 4;

let HEATMAP_SLICES = Array(30)
  .fill(null)
  .map(() => []);

// the purpose of this component is to make a separate three.js instance
// and dedicate it to its own working
function _EQHeatmap() {
  const [, render] = useState(false);

  const { audioProcessingStore } = store;

  const magnitudeObjects = audioProcessingStore.magnitudeObjects;

  const barWidth = 1 * xScale;

  const barHeight = BAR_SCALING_FACTOR / magnitudeObjects.length;

  useEffect(() => {
    const column = [];
    for (let i = 0; i < magnitudeObjects.length; i++) {
      const magnitudeObject = magnitudeObjects[i];

      const { avgValueDb } = magnitudeObject;

      const intensity = Math.log10(Math.max(avgValueDb, 0.01));

      const freqChunk = intensity;

      column.push(freqChunk);
    }

    const newHeatmapSlices = HEATMAP_SLICES.slice(1);

    newHeatmapSlices.push(column);

    HEATMAP_SLICES = newHeatmapSlices;

    render();
    //
  }, [barWidth, magnitudeObjects]);

  return (
    <Paper sx={{ bgcolor: "black" }}>
      <ThreeRenderer
        titleComponent={<Typography color="white">EQ Heatmap</Typography>}
        stackProps={{
          spacing: 0,
        }}
      >
        {HEATMAP_SLICES.map((column, i) =>
          column.map((freqChunk, j) => {
            const intensity = freqChunk;

            // const color = computeColorBasedOnIndex(j, magnitudeObjects);

            const yOffset = j * (barHeight * yScale); // Map to scale using barWidth

            return (
              <mesh
                key={`${i}-${j}`}
                //
                position={[
                  i * barWidth + LEFT_ORIGIN,
                  yOffset + BOTTOM_ORIGIN,
                  0,
                ]}
              >
                <planeGeometry args={[barWidth, barHeight * yScale]} />
                <meshBasicMaterial
                  color={"cyan"}
                  transparent={true}
                  opacity={intensity + 0.2}
                />
              </mesh>
            );
          })
        )}
      </ThreeRenderer>
    </Paper>
  );
}

const EQHeatmap = observer(_EQHeatmap);

// const EQHeatmap = _EQHeatmap;

export { EQHeatmap };
