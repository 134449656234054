import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
} from "@mui/material";
import store from "../../mobx";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  ElementsConsumer,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { fetchFromApiServer } from "../../services/api";
import { Box, Stack } from "@mui/system";
import { STRIPE_PRODUCTS } from "../../constants";
import { useNavigate } from "react-router";
import { setSubscriptionCongratulationsDialog } from "./setSubscriptionCongratsDialog";

const { dialogStore, snackbarStore } = store;

const stripePromise = loadStripe(
  "pk_test_51Ni7ncBik4ttaSLwkMzQQfUjcVaG7rCZ5n6RvYN1zNmihnK5IzR8Z4sImspEkPrFsZGTNw2z1Td3CI0OqA6CSoqS00GCg67VNc"
);

// const createIntentUrl =
//   process.env.NODE_ENV === "production"
//     ? "https://createintent-5gnvjtys6a-uc.a.run.app"
//     : "http://127.0.0.1:5001/watchdog-6de84/us-central1/createIntent";

// const verifyIntentUrl =
//   process.env.NODE_ENV === "production"
//     ? "https://verifyintent-5gnvjtys6a-uc.a.run.app"
//     : "http://127.0.0.1:5001/watchdog-6de84/us-central1/verifyIntent";

const createSubscriptionUrl =
  process.env.NODE_ENV === "production"
    ? "https://createsubscription-5gnvjtys6a-uc.a.run.app"
    : "http://127.0.0.1:5001/watchdog-6de84/us-central1/createSubscription";

// const verifyIntentUrl = "https://verifyintent-5gnvjtys6a-uc.a.run.app";

const AMOUNT = 3_00;

const CheckoutForm = ({ stripe, elements }) => {
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        // Show error to your customer
        return;
      }

      setSubmitted(true);

      // Collect payment method details
      const { paymentMethod, error: paymentMethodError } =
        await stripe.createPaymentMethod({
          elements,
        });

      console.log(
        "Payment method is: ",
        paymentMethod,
        " Error is: ",
        paymentMethodError
      );

      if (paymentMethodError) {
        console.error(paymentMethodError);
        setSubmitted(false);
        return;
      }

      // Create the PaymentIntent and obtain clientSecret
      const res = await fetchFromApiServer(createSubscriptionUrl, {
        method: "POST",
        body: JSON.stringify({
          plan: STRIPE_PRODUCTS.subscriptions.watchdogPro,
          paymentMethod,
        }),
      });

      if (!res.ok) {
        throw new Error("Subscription setup failed");
      }

      setSubscriptionCongratulationsDialog();
    } catch (error) {
      console.error(error);
      setSubmitted(false);
      snackbarStore.set.message("Something went wrong, please try again later");
      dialogStore.set.open(false);
    }
  };

  return (
    <>
      <DialogContent
        sx={{
          minHeight: 300,
          width: "100%",
          pb: 0,
          overflowY: "hidden",
        }}
      >
        <PaymentElement options={{}} />
        <Fade in={submitted}>
          <Stack
            alignItems="center"
            sx={{ width: "100%", height: "100%", pt: 4 }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </Stack>
        </Fade>
      </DialogContent>

      <DialogActions sx={{ pt: 1, pb: 2 }}>
        {/* <Button
          variant="contained"
          sx={{
            fontFamily: "PermanentMarker",
            WebkitTextStroke: 1,
            WebkitTextStrokeColor: "black",
            letterSpacing: 4,
            borderRadius: 4,
            border: "1px solid",
            borderColor: "secondary.main",
          }}
          onClick={handleSubmit}
        >
          Subscribe
        </Button> */}
        <Button
          variant="text"
          color="error"
          onClick={() => store.dialog.set.open(false)}
          sx={{
            ml: 2,
            fontSize: 16,
            borderColor: "grey.300",
            color: "grey.300",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleSubmit}
          sx={{ mr: 2, fontSize: 16 }}
        >
          Subscribe
        </Button>
      </DialogActions>
    </>
  );
};

const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

export const setSubscribeDialog = () => {
  dialogStore.set.dialog({
    fullScreen: store.mediaBreakpointStore.isPhone,
    Title: () => <DialogTitle>Subscribe</DialogTitle>,
    Content: () => (
      <Elements
        stripe={stripePromise}
        options={{
          mode: "subscription",
          amount: AMOUNT,
          paymentMethodCreation: "manual",
          currency: "usd",
          appearance: {
            // theme: "flat",
            labels: "floating",
            variables: {
              //   colorText: "#fff",
            },
          },
        }}
      >
        <InjectedCheckoutForm />
      </Elements>
    ),
  });
  dialogStore.set.open(true);
};
