import { CanvasTexture, Color, Sprite, SpriteMaterial } from "three";

function throttle(callback, delay) {
  let timeoutReached = true;

  return function (...args) {
    if (timeoutReached === true) {
      timeoutReached = false;
      setTimeout(() => {
        callback(...args);
        timeoutReached = true;
      }, delay);
    }

    return;
  };
}

function graphFromPipeline({ left: leftPipeline, right: rightPipeline }) {
  const leftPipe = _graphFromPipeline(leftPipeline);
  const rightPipe = _graphFromPipeline(rightPipeline);

  return leftPipe;

  // return [leftPipe, rightPipe];
}

function _graphFromPipeline(pipeline) {
  let prevEl;

  for (const constructor of pipeline) {
    if (prevEl === undefined) {
      const el = constructor();
      prevEl = el;
      continue;
    }

    const el = constructor(prevEl);
    prevEl = el;
  }

  return prevEl;
}

function getColorFromIntensity(intensity) {
  // Clamp intensity between 0 and 1
  intensity = Math.min(Math.max(intensity, 0), 1);

  let color = new Color();

  if (intensity <= 0.33) {
    // From cyan (#00FFFF) to yellow (#FFFF00)
    const t = intensity / 0.33;
    color.r = t;
    color.g = 1;
    color.b = 1 - t;
  } else if (intensity <= 0.66) {
    // From yellow (#FFFF00) to orange (#FFA500)
    const t = (intensity - 0.33) / 0.33;
    color.r = 1;
    color.g = 1 - 0.25 * t; // Green from 1 to 0.75
    color.b = 0;
  } else {
    // From orange (#FFA500) to red (#FF0000)
    const t = (intensity - 0.66) / 0.34;
    color.r = 1;
    color.g = 0.75 - 0.75 * t; // Green from 0.75 to 0
    color.b = 0;
  }

  return color;
}

const colors = [
  new Color(1, 0, 0), // Red
  new Color(1, 0.5, 0), // Orange
  new Color(1, 1, 0), // Yellow
  new Color(0, 1, 0), // Green
  new Color(0, 0, 1), // Blue
  new Color(0.29, 0, 0.51), // Indigo
  new Color(0.56, 0, 1), // Violet
];

// Interpolate the color between the defined ROYGBIV colors
const segment = 1 / (colors.length - 1);

function computeColorBasedOnIndex(i, array) {
  // Calculate ROYGBIV color based on the index
  const t = i / (array.length - 1); // Normalized index (0 to 1)

  const startIdx = Math.floor(t / segment);
  const endIdx = Math.min(startIdx + 1, colors.length - 1);
  const localT = (t - startIdx * segment) / segment;

  const color = colors[startIdx].clone().lerp(colors[endIdx], localT);
  return color;
}

function makeTextSprite(text) {
  // Add label for each bar
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = 256;
  canvas.height = 64;

  // Style the label
  context.fillStyle = "white";
  context.font = "24px Arial";
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(text, canvas.width / 2, canvas.height / 2);

  // Create a texture from the canvas
  const texture = new CanvasTexture(canvas);
  const spriteMaterial = new SpriteMaterial({ map: texture });
  const sprite = new Sprite(spriteMaterial);

  return sprite;
}

export {
  throttle,
  graphFromPipeline,
  computeColorBasedOnIndex,
  makeTextSprite,
  getColorFromIntensity,
};
