import { makeAutoObservable } from "mobx";
import GlobalStore from "../globalStore";

class FilesystemStore {
  files = [];
  playingFile = null;
  isLoading = false;

  constructor(_ = new GlobalStore()) {
    this._ = _;
    makeAutoObservable(this);
  }

  setFiles = async (files) => {
    this.files = files;
  };
}

export default FilesystemStore;
