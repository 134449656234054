import { makeAutoObservable } from "mobx";
import FileSystemStore from "./stores/filesystem";
import AudioProcessingStore from "./stores/audioProcessing";
import ClipperStore from "./stores/clipper";
import UserStore from "./stores/user";
import DrawerStore from "./stores/drawer";
import DialogStore from "./stores/dialog";
import SnackbarStore from "./stores/snackbar";
import AppBarStore from "./stores/appbar";
import MediaBreakpointStore from "./stores/mediaBreakpoint";
import GlobalFxStore from "./stores/globalFx";

class GlobalStore {
  data = [];
  isLoading = false;

  constructor() {
    this.userStore = new UserStore(this);
    this.drawerStore = new DrawerStore(this);
    this.dialogStore = new DialogStore(this);
    this.snackbarStore = new SnackbarStore(this);
    this.appBarStore = new AppBarStore(this);
    this.mediaBreakpointStore = new MediaBreakpointStore(this);
    this.globalFxStore = new GlobalFxStore(this);

    this.fileSystemStore = new FileSystemStore(this);
    this.audioProcessingStore = new AudioProcessingStore(this);
    this.clipperStore = new ClipperStore(this);
    makeAutoObservable(this);
  }
}

export default GlobalStore;
