import { Box, CardMedia, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import { Stack } from "@mui/system";

import store from "../../mobx";
import { ArrowRight } from "@mui/icons-material";

import { SubsectionStack } from "./SubsectionStack";

export const FeaturesCarousel = observer(function FeaturesCarousel({
  title = "How can watchdog help your business?",
  spacing = 8,
}) {
  const { isPhone } = store.mediaBreakpointStore;

  const items = [
    {
      src: "/mix_quick_demo5.png",
      header: "Easy to Understand",
      text: (
        <span>
          Say goodbye to a confusing mixing process. <br />
          <br />
          Mix.Quick takes the heavy lifting out by giving you an easy process to
          follow.
        </span>
      ),
      animate: true,
    },
    {
      icon: true,
    },
    {
      src: "/mix_quick_demo5.png",
      header: "Auto Gain",
      text: (
        <span>
          One of the most annoying parts of clipping, saturating, and widening
          is having to looks at your LUFS meter to adjust your gain. <br />
          <br />
          Not anymore!
        </span>
      ),
      animate: true,
    },
    {
      icon: true,
    },
    {
      src: "/mix_quick_demo5.png",
      header: "Versatile",
      text: (
        <span>
          Use it for clipping. <br />
          Use it for saturation. <br />
          Use it for widening your sound. <br />
          <br />
          Or just use it to auto match your loudness if you prefer to use other
          plugins.
        </span>
      ),
      animate: true,
    },
  ];

  return (
    <SubsectionStack title={title} spacing={spacing}>
      <Stack
        width="100%"
        height={"fit-content"}
        direction={isPhone ? "column" : "row"}
        alignItems={isPhone ? "center" : "flex-start"}
        justifyContent="center"
        spacing={isPhone ? 5 : 12}
      >
        {items.map(({ icon, src, header, text, animate }, index) => {
          if (icon) {
            return isPhone ? null : (
              <Box
                key={index}
                display="flex"
                alignSelf="center"
                sx={{ height: "100%", pb: 18.5 }}
              >
                <ArrowRight
                  sx={{
                    height: 40,
                    width: 40,
                    color: "#fff2",
                  }}
                />
              </Box>
            );
          }

          const percentDifference = `${index * 2 + 2}`;
          return (
            <Stack
              key={index}
              alignItems="center"
              justifyContent="center"
              spacing={2}
              sx={{
                width: 272,
              }}
            >
              <Typography
                textAlign="center"
                sx={(theme) => ({
                  fontFamily: "PermanentMarker",
                  fontSize: 22,
                  lineHeight: "18px",
                  color: "black",
                  WebkitTextStroke: "0.5px",
                  // WebkitTextStrokeColor: theme.palette.altPrimary.main,
                })}
              >
                {header}
              </Typography>
              <Stack
                alignItems="center"
                sx={{
                  bgcolor: "black",
                  width: 272,
                  borderRadius: 4,
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    bgcolor: "black",
                    width: "fit-content",
                    borderRadius: 4,
                  }}
                >
                  <CardMedia
                    component="img"
                    src={src}
                    sx={{
                      height: 192,
                      borderRadius: 4,
                      bgcolor: "black",
                      objectFit: "contain",
                      ...(animate
                        ? {
                            animation: `rotate${percentDifference} 10s linear`,
                            [`@keyframes rotate${percentDifference}`]: {
                              "0%": {
                                transform:
                                  "rotateX(8deg) rotateY(0deg) scale(1)",
                              },
                              [`${percentDifference}%`]: {
                                transform:
                                  "rotateX(8deg) rotateY(90deg) scale(1)",
                              },
                              "50%": {
                                transform:
                                  "rotateX(0deg) rotateY(0deg) scale(1)",
                              },
                              "75%": {
                                transform:
                                  "rotateX(0deg) rotateY(0deg) scale(1)",
                              },

                              "100%": {
                                transform:
                                  "rotateX(0deg) rotateY(0deg) scale(1)",
                              },
                            },
                          }
                        : {}),
                    }}
                  />
                </Box>
              </Stack>

              <Typography
                textAlign="center"
                sx={{
                  fontSize: 20,
                  color: "black",
                }}
              >
                {text}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </SubsectionStack>
  );
});
