import { observer } from "mobx-react-lite";
import { Paper, Stack } from "@mui/material";
import { MeterdB } from "./SignalRenderer/MeterdB";
import { EQVisualizer } from "./SignalRenderer/EqVisualizer";
import { EQHeatmap } from "./SignalRenderer/EqHeatmap";
import { MacroWaveForm } from "./SignalRenderer/MacroWaveForm";

const SCOPES = [
  {
    Component: MacroWaveForm,
  },
  {
    Component: EQVisualizer,
  },
  {
    Component: EQHeatmap,
  },
];

function Graphs() {
  return (
    <Paper sx={{ bgcolor: "#DDD4", p: 2, mt: 2 }}>
      <Stack spacing={2}>
        <Stack alignItems="center" sx={{ mt: 2 }} direction="row" spacing={3.1}>
          {SCOPES.map(({ Component }, i) => (
            <Component key={i} />
          ))}
        </Stack>
        <MeterdB />
      </Stack>
    </Paper>
  );
}

export default observer(Graphs);
