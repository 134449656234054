function constructFrequencyBins({
  minFrequency,
  maxFrequency,
  totalNumberOfBins,
  floorFreq,
}) {
  const frequencyBins = getLogarithmicBins(
    minFrequency,
    maxFrequency,
    totalNumberOfBins,
    floorFreq
  );

  return frequencyBins;
}

function getLogarithmicBins(fMin, fMax, nBins, floorFreq = 0) {
  const frequencyBins = [];
  for (let i = 0; i <= nBins; i++) {
    // Compute each edge using the logarithmic spacing formula
    const edgeFreq = fMin * Math.pow(fMax / fMin, i / nBins);

    if (edgeFreq >= floorFreq) {
      frequencyBins.push(edgeFreq);
    }
  }
  return frequencyBins;
}

function constructMagnitudeObjects(frequencyBins, real, imag, sampleRate) {
  // Calculate magnitudes
  const magnitudes = [];
  const fftSize = (real.length - 1) * 2; // Calculate FFT size from real length

  for (let i = 0; i < real.length - 1; i++) {
    const re = real[i];
    const im = imag[i];

    const magnitude = Math.sqrt(re ** 2 + im ** 2);

    magnitudes.push({
      magnitude,
      frequency: (i * sampleRate) / fftSize,
    });
  }

  const magnitudeObjects = Array(frequencyBins.length)
    .fill(null)
    .map((v, i) => {
      const frequency = frequencyBins[i];
      return {
        frequency,
        values: [],
        avgValue: 0,
        normAvgValue: 0,
      };
    });

  for (let i = 0; i < magnitudes.length; i++) {
    const { magnitude, frequency } = magnitudes[i];

    const minEdgeDistance = {
      distance: Number.MAX_VALUE,
      index: 0,
      binFreq: null,
    };

    for (let j = 0; j < frequencyBins.length; j++) {
      const binEdge = frequencyBins[j];

      const distance = Math.abs(binEdge - frequency);

      if (distance <= minEdgeDistance.distance) {
        minEdgeDistance.distance = distance;
        minEdgeDistance.index = j;
      }
    }

    const magnitudeObject = magnitudeObjects[minEdgeDistance.index];

    magnitudeObject.values.push(magnitude);
  }

  let minAvgValue = Number.MAX_VALUE;
  let maxAvgValue = Number.MIN_VALUE;

  for (const magnitudeObject of magnitudeObjects) {
    const total = magnitudeObject.values.reduce((acc, v) => acc + v, 0);

    const avgValue =
      magnitudeObject.values.length === 0
        ? 0.00001
        : total / magnitudeObject.values.length;

    magnitudeObject.avgValue = avgValue;

    if (avgValue < minAvgValue) {
      minAvgValue = avgValue;
    }
    if (avgValue > maxAvgValue) {
      maxAvgValue = avgValue;
    }
  }

  // normalize the avgValues
  for (const magnitudeObject of magnitudeObjects) {
    const { avgValue } = magnitudeObject;

    // Normalize the average value
    magnitudeObject.normAvgValue = avgValue / maxAvgValue;

    // Convert to decibels (adding a small value to avoid log of zero)

    magnitudeObject.avgValueDb = computeValueAsdB(avgValue);
  }

  return magnitudeObjects;
}

function computeValueAsdB(value) {
  const epsilon = 1e-10; // Small value to prevent log(0) issues
  return Math.log10(value + epsilon);
}

export { constructFrequencyBins, constructMagnitudeObjects };
