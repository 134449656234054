import store from "../../mobx";
import { ContentCopy, Edit } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";

import { observer } from "mobx-react-lite";
import copy from "copy-to-clipboard";

import SingleCardPage from "../../components/Page/SingleCardPage";

import { setSubscribeDialog } from "../../components/Dialog/setSubscribeDialog";
import { setUnsubscribeDialog } from "../../components/Dialog/setUnsubscribeDialog";
import { fetchFromApiServer } from "../../services/api";

import TimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";
import en from "javascript-time-ago/locale/en.json";
TimeAgo.addDefaultLocale(en);

export default observer(function Signup() {
  const { userStore } = store;

  return (
    <>
      <SingleCardPage
        cardHeaderProps={{
          title: "Account Details",
        }}
        cardContentProps={{}}
        cardContentChildren={
          userStore.loading ? (
            <CircularProgress />
          ) : (
            <Stack spacing={4}>
              <Typography>Team</Typography>
            </Stack>
          )
        }
        // Action={}
      />
    </>
  );
});
