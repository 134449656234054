import { useLocation } from "react-router";
import LandingPage from "./routes/landingPage";
import Home from "./routes/home";
import Account from "./routes/account";
import AppBar from "./components/AppBar";
import Drawer from "./components/Drawer";
import { Routes, Route } from "react-router-dom";

import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import store from "./mobx";
import { useEffect } from "react";
import Snowfall from "react-snowfall";
import { Fade } from "@mui/material";
import MixQuick from "./routes/plugins/MixQuick";

function RouteContainer() {
  const { isPhone } = store.mediaBreakpointStore;
  const { globalFxStore } = store;

  const location = useLocation();

  useEffect(() => {
    if (isPhone === false) {
      store.drawerStore.set.open(false);
    } else if (isPhone === true) {
      store.drawerStore.set.open(false);
    }
  }, [isPhone]);

  const onRootPath = location.pathname === "/";

  return (
    <Box
      pl={
        !onRootPath && !isPhone && store.drawerStore.open
          ? "300px"
          : !onRootPath && !isPhone
          ? "56px"
          : undefined
      }
      height="100vh"
      width="100vw"
      sx={(theme) => {
        return {
          // overflow: "overlay",
          transition: theme.transitions.create("all"),
        };
      }}
    >
      <Fade in={globalFxStore.snowing}>
        <Box>
          <Snowfall
            snowflakeCount={400}
            speed={[1.0, 5.0]}
            color="pink"
            style={{
              zIndex: 1.3e3,
            }}
          />
        </Box>
      </Fade>
      {/* {!onRootPath && <Drawer />} */}
      {!onRootPath && <Drawer />}
      {/* {(isPhone && <AppBar />) ||
        (!isPhone && !store.drawerStore.open && <AppBar />)} */}
      {isPhone && <AppBar />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/account" element={<Account />} />
        <Route path="/mixquick" element={<MixQuick />} />
      </Routes>
    </Box>
  );
}

export default observer(RouteContainer);
