import { Box, Slider, Stack, Typography } from "@mui/material";

function ModulatorSlider({
  label = "Default Label",
  defaultValue = 0,
  marks,
  onChange = () => undefined,
  ...props
}) {
  return (
    <Stack sx={{ mt: 4 }} justifyContent={"flex-end"}>
      <Stack
        sx={{
          height: 200,
          //
          // bgcolor: "red",
        }}
        spacing={2}
      >
        <Slider
          onChange={onChange}
          orientation="vertical"
          defaultValue={defaultValue}
          marks={marks}
          valueLabelDisplay="auto"
          sx={{
            "& .MuiSlider-markLabel": {
              fontSize: "0.66rem", // Adjust font size here
            },
            color: "altPrimary.main",
          }}
          {...props}
        />
        <Box>
          <Typography>{label}</Typography>
        </Box>
      </Stack>
    </Stack>
  );
}

export { ModulatorSlider };
