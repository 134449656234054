function gainToDb(input) {
  const dB = 20 * Math.log10(input);

  return dB;
}

function dbToGain(dB) {
  const gain = Math.pow(10, dB / 20);
  return gain;
}

module.exports = {
  gainToDb,
  dbToGain,
};
