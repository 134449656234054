// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
  doc,
  where,
  onSnapshot,
  query,
  arrayRemove,
  documentId,
} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC9ZLVHVqjPRDPmRUvpv66fKKk-V-aqWdA",
  authDomain: "last-year-18294.firebaseapp.com",
  projectId: "last-year-18294",
  storageBucket: "last-year-18294.firebasestorage.app",
  messagingSenderId: "894814475251",
  appId: "1:894814475251:web:fb99216f5bfc9b1a66b886",
  measurementId: "G-ZGX6HL9QFN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);

const db = getFirestore(app);

export const firestore = {
  collection: {
    add: ({ path, data }) => addDoc(collection(db, path), data),
    get: {
      docs: (query) => getDocs(query),
    },
  },
  doc: {
    set: ({ path, data }, options) => setDoc(doc(db, path), data, options),
    update: ({ path, data }) => updateDoc(doc(db, path), data),
    removeItemFromArray: ({ path, field, value }) =>
      updateDoc(doc(db, path), {
        [field]: arrayRemove(value),
      }),
    get: ({ path }) => getDoc(doc(db, path)),
    delete: ({ path }) => deleteDoc(doc(db, path)),
  },
};

const ogCollection = collection;

const ogDoc = doc;

export {
  query,
  collection,
  where,
  ogCollection,
  ogDoc,
  onSnapshot,
  db,
  auth,
  documentId,
};
