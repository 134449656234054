import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";

export const WatchdogText = observer(function WatchdogText({
  disableFullText,
  sx,
  ...props
}) {
  const navigate = useNavigate();
  return (
    <Box onClick={() => navigate("/home")}>
      <Typography
        textAlign="center"
        {...props}
        sx={(theme) => ({
          fontFamily: "PermanentMarker",
          fontSize: 28,
          lineHeight: "36px",
          WebkitTextStroke: 1,
          WebkitTextStrokeColor: theme.palette.secondary.main,
          WebkitTapHighlightColor: "transparent",
          cursor: "pointer",
          color: "black",
          ...sx,
        })}
      >
        {disableFullText ? "L" : "Last.Year"}
      </Typography>
    </Box>
  );
});

export default WatchdogText;
