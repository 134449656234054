import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Typography,
  Grid2,
  IconButton,
  Stack,
} from "@mui/material";
import { Delete, UploadFile, PlayArrow, Stop } from "@mui/icons-material";
import store from "../../../../mobx";

function FileUploader() {
  const {
    audioProcessingStore,
    fileSystemStore: { files, setFiles },
  } = store;

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFiles(files);
  };

  const handleFileDelete = (index) => {
    const updatedFiles = [...files.slice(0, index), ...files.slice(index + 1)];

    setFiles(updatedFiles);
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={2}
        sx={{
          width: "100%",
        }}
      >
        <Box>
          <Button
            variant="contained"
            component="label"
            onClick={
              audioProcessingStore.playing.signal
                ? audioProcessingStore.stop
                : audioProcessingStore.startSignal
            }
            startIcon={
              audioProcessingStore.playing.signal ? (
                <Stop sx={{ fill: "red" }} />
              ) : (
                <PlayArrow sx={{ fill: "lime" }} />
              )
            }
            sx={{ mt: 2, bgcolor: "black" }}
          >
            {audioProcessingStore.playing.signal
              ? "Stop Signal"
              : "Start Signal"}
          </Button>
        </Box>

        <Box>
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadFile />}
            sx={{ mt: 2, bgcolor: "black" }}
          >
            Choose Files
            <input type="file" multiple hidden onChange={handleFileChange} />
          </Button>
        </Box>
        {files.length > 0 && (
          <Box mt={3}>
            <Typography variant="subtitle1" gutterBottom>
              Selected Files
            </Typography>
            <Grid2 container spacing={2}>
              {files.map((file, index) => (
                <Grid2 xs={12} sm={6} md={4} key={index}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    p={1}
                    border="1px solid #ddd"
                    borderRadius="4px"
                  >
                    <IconButton
                      color="success"
                      size="small"
                      onClick={() => {
                        audioProcessingStore.playing.sample
                          ? audioProcessingStore.stop()
                          : audioProcessingStore.startSample(files[index]);
                      }}
                    >
                      {audioProcessingStore.playing.sample ? (
                        <Stop sx={{ fill: "red" }} />
                      ) : (
                        <PlayArrow sx={{ fill: "lime" }} />
                      )}
                    </IconButton>
                    <Stack direction="column">
                      <Typography
                        variant="body2"
                        noWrap
                        title={file.name}
                        sx={{ maxWidth: "150px" }}
                      >
                        {file.name}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {(file.size / 1024 ** 2).toFixed(2)} MB
                      </Typography>
                    </Stack>
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => {
                        audioProcessingStore.stop();
                        handleFileDelete(index);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                </Grid2>
              ))}
            </Grid2>
          </Box>
        )}
      </Stack>
    </Box>
  );
}

export default observer(FileUploader);
